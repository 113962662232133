// set the drawer detail element
const $targetDrawerDetail = document.getElementById("drawer-detail");
// options with default values
const optionsDetailRight = {
    placement: "right",
    backdrop: true,
    bodyScrolling: false,
    edge: false,
    edgeOffset: "",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30",
    onHide: () => {
    },
    onShow: () => {
    },
    onToggle: () => {
    },
};

// instance options object
const instanceOptionsDrawerDetail = {
    id: "drawer-detail",
    override: true,
};

new Drawer(
    $targetDrawerDetail,
    optionsDetailRight,
    instanceOptionsDrawerDetail
);
