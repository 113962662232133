const $modalElement = document.getElementById("modal-product");

const modalOptions = {
    placement: "center",
    backdrop: "dynamic",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
    onHide: () => {
        window.history.pushState({}, '', '/');
    },
    onShow: () => {
        const optionsScroll = document.getElementById('options-scroll');
        const modalProduct = document.getElementById('modal-product');

        if (optionsScroll) {
            optionsScroll.scrollTop = 0;
        }

        if (modalProduct) {
            modalProduct.scrollTop = 0;
        }
    },
    onToggle: () => {
    },
};
const instanceOptionsModalProduct = {
    id: "modal-product",
    override: true,
};

new Modal($modalElement, modalOptions, instanceOptionsModalProduct);
