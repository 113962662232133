// set the dropdown address element
const $targetElDropdownAddress = document.getElementById("dropdown-address");

// set the element that trigger the dropdown menu on click
const $triggerElDropdownAddress = document.getElementById(
    "dropdown-address-input"
);

// options with default values
const optionsDropdownAddress = {
    placement: "bottom",
    triggerType: "none",
    offsetSkidding: 0,
    offsetDistance: 0,
    delay: 300,
    ignoreClickOutsideClass: "dropdown-address-ignore",
    onHide: () => {
    },
    onShow: () => {
    },
    onToggle: () => {
    },
};

// instance options object
const instanceOptionsDropdownAddress = {
    id: "dropdown-address",
    override: true,
};

new Dropdown(
    $targetElDropdownAddress,
    $triggerElDropdownAddress,
    optionsDropdownAddress,
    instanceOptionsDropdownAddress
);
