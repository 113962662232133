// set the drawer cart element
const $targetDrawerCart = document.getElementById("drawer-cart");
// options with default values
const optionsRight = {
    placement: "right",
    backdrop: true,
    bodyScrolling: false,
    edge: false,
    edgeOffset: "",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30",
    onHide: () => {
    },
    onShow: () => {
    },
    onToggle: () => {
    },
};

// instance options object
const instanceOptionsDrawerCart = {
    id: "drawer-cart",
    override: true,
};

new Drawer($targetDrawerCart, optionsRight, instanceOptionsDrawerCart);
