const $modalElementCompanyTime = document.getElementById("modal-company-time");

const modalOptionsCompanyTime = {
    placement: "top-center",
    backdrop: "dynamic",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
    onHide: () => {
    },
    onShow: () => {
    },
    onToggle: () => {
    },
};
const instanceOptionsModalCompanyTime = {
    id: "modal-company-time",
    override: true,
};

new Modal(
    $modalElementCompanyTime,
    modalOptionsCompanyTime,
    instanceOptionsModalCompanyTime
);
