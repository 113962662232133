const $modalElementEditAddress = document.getElementById("modal-edit-address");

const modalOptionsEditAddress = {
    placement: "top-center",
    backdrop: "dynamic",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
    onHide: () => {
        Livewire.dispatch('close-modal-address-edit-outside');
    },
    onShow: () => {
    },
    onToggle: () => {
        Livewire.dispatch('close-modal-address-edit-outside');
    },
};
const instanceOptionsModalEditAddress = {
    id: "modal-edit-address",
    override: true,
};

new Modal(
    $modalElementEditAddress,
    modalOptionsEditAddress,
    instanceOptionsModalEditAddress
);
