const $modalElementAddress = document.getElementById("modal-address");

const modalOptionsAddress = {
    placement: "top-center",
    backdrop: "dynamic",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
    onHide: () => {
        Livewire.dispatch('close-modal-address-outside');
    },
    onShow: () => {
    },
    onToggle: () => {
        Livewire.dispatch('close-modal-address-outside');
    },
};
const instanceOptionsModalAddress = {
    id: "modal-address",
    override: true,
};

new Modal(
    $modalElementAddress,
    modalOptionsAddress,
    instanceOptionsModalAddress
);
