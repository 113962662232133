// set the drawer menu element
const $targetDrawerMenu = document.getElementById("drawer-menu");
// options with default values
const optionsMenuLeft = {
    placement: "left",
    backdrop: true,
    bodyScrolling: false,
    edge: false,
    edgeOffset: "",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30",
    onHide: () => {
    },
    onShow: () => {
    },
    onToggle: () => {
    },
};

// instance options object
const instanceOptionsDrawerMenu = {
    id: "drawer-menu",
    override: true,
};

new Drawer($targetDrawerMenu, optionsMenuLeft, instanceOptionsDrawerMenu);
