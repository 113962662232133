// set the drawer otp element
const $targetDrawerOtp = document.getElementById("drawer-otp");
// options with default values
const optionsOtpTop = {
    placement: "top",
    backdrop: true,
    bodyScrolling: false,
    edge: false,
    edgeOffset: "",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30",
    onHide: () => {
    },
    onShow: () => {
    },
    onToggle: () => {
    },
};

// instance options object
const instanceOptionsDrawerOtp = {
    id: "drawer-otp",
    override: true,
};

new Drawer($targetDrawerOtp, optionsOtpTop, instanceOptionsDrawerOtp);
