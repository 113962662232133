
import BlazeSlider from 'blaze-slider';

document.addEventListener('livewire:init', () => {
    const el = document.querySelector('.blaze-slider')

    if (el) {

        const blazeTrack = document.querySelector('.blaze-track')
        const cantidadElementos = blazeTrack.querySelectorAll('.elemento').length;

        const sliderOptions = {
            all: {
                slidesToShow: 1,
                slidesToScroll: 1,
                loop: true,
                draggable: true,
                enableAutoplay: true,
                stopAutoplayOnInteraction: true,
                autoplayInterval: 3000,
                autoplayDirection: 'to left',
                transitionDuration: 500,
                transitionTimingFunction: 'ease',
            },
            '(min-width: 1024px)': {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        };

        if (cantidadElementos == 1) {
            const styleSheet = document.createElement('style');
            styleSheet.type = 'text/css';

            const styles = `
                .blaze-prev {   
                   display: none !important;
                }
                .blaze-next {
                   display: none !important;
                }
                `;

            styleSheet.appendChild(document.createTextNode(styles));

            document.head.appendChild(styleSheet);
        }

        // Verificar si hay más de 3 slides y aplicar la configuración específica
        if (cantidadElementos < 4) {
            sliderOptions['(min-width: 1024px)']['slidesToShow'] = 1;
            sliderOptions['(min-width: 1024px)']['slidesToScroll'] = 1;

            const styleSheet = document.createElement('style');
            styleSheet.type = 'text/css';

            const styles = `@media only screen and (min-width: 1024px) {
                .blaze-track {
                    margin-left: 0% !important;
                    margin-right: 0% !important;
                }
            }`;

            styleSheet.appendChild(document.createTextNode(styles));

            document.head.appendChild(styleSheet);
        }

        const slider = new BlazeSlider(el, sliderOptions);
    }

})